import React, { useState } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { object } from 'prop-types'
import CookieConsent, { Cookies } from 'react-cookie-consent'

import CloseIcon from '@images/close.svg'

const CookiePopup = styled(({ data, className }) => {
  const [closeCookie, setcloseCookie] = useState(false)
  // eslint-disable-next-line no-undef
  return Cookies.get('gatsby-gdpr-google-tagmanager') ? dataLayer.push({ event: 'iubenda_consent_given' }) : (
    <div className={`${className} ${closeCookie ? 'hide' : ''}`}>
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText={data.acceptButton}
        enableDeclineButton
        declineButtonText={data.secondButtonTitle}
        setDeclineCookie={false}
        onAccept={() => {
          // eslint-disable-next-line no-undef
          dataLayer.push({ event: 'iubenda_consent_given' })
        }}
        onDecline={() => {
          navigate(data.secondButtonLink)
        }}
        flipButtons
        cookieName="gatsby-gdpr-google-tagmanager"
      >
        <button
          className="close-icon-btn"
          onClick={() => {
            setcloseCookie(!closeCookie)
          }}
        >
          <CloseIcon />
        </button>
        <h6>{data.title}</h6>
        <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
      </CookieConsent>
    </div>
  )
})`
  display: block;

  &.hide {
    display: none !important;
  }

  .CookieConsent {
    background: #ffffff;
    box-shadow: 0px 12px 16px rgba(9, 9, 10, 0.23);
    padding: 24px 15px 30px;
    position: fixed;
    right: 0;
    z-index: 999;
    width: 100%;

    .close-icon-btn {
      background: transparent;
      border: none;
      padding: 0;
      position: absolute;
      right: 23px;
      top: 24px;

      &:hover,
      &:focus {
        outline: none;
      }
    }

    @media (min-width: 768px) {
      padding: 40px 32px;
      width: 540px;
    }

    @media (min-width: 576px) {
      padding: 32px 30px 28px 30px;
    }

    h6 {
      color: #000000;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    p {
      color: #767676;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.03em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      margin-bottom: 40px;
    }

    button:not(.close-icon-btn) {
      background-color: transparent;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      padding: 16px 35px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        text-align: center;
        width: 100%;
      }

      &#rcc-confirm-button {
        background: #2b58ca;
        border: 1px solid #2b58ca;
        color: #ffffff;
        margin-right: 15px;

        @media (max-width: 768px) {
          margin-bottom: 10px;
          margin-right: 0;
        }
      }

      &#rcc-decline-button {
        border: 1px solid #2b58ca;
        color: #2b58ca;
      }

      &:focus {
        outline: none;
      }
    }
  }
`

CookiePopup.propTypes = {
  data: object.isRequired
}

CookiePopup.defaultProps = {
  data: {}
}

export default CookiePopup
