import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const Link = styled(props => <GatsbyLink {...props} />)`
  background-image: none;
  color: ${colors.abbey};
  display: table-cell;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 18px;
  padding: 20px 0;
  position: relative;
  text-decoration: none;
  transition: all 100ms linear;
  width: 100%;

  &:hover {
    color: ${colors.black};
    text-decoration: none;
  }

  &.active {
    &:after {
      background: ${colors.fern};
      border-radius: 50%;
      content: "";
      height: 10px;
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 100ms linear;
      width: 10px;
    }
  }
`

export default Link
