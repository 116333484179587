import React, { useEffect } from 'react'
import { node } from 'prop-types'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'

import Header from './components/Header'
import Footer from './components/Footer'

import NavigationProvider from '@providers/navigationProvider'
import PopupProvider from '@providers/popupProvider'

import { useSiteMetadata } from './modules/graphql'

const Layout = styled(({ children, className }) => {
  const { title } = useSiteMetadata()

  useEffect(() => {
    const { hash } = window.location;
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  })

  return (
    <div className={className} id="main-layout">
      <Helmet>
        <meta
          name="google-site-verification"
          content="CHtATme5PPPU4BPc-Sax_luWHYuHF5wmeeVhqIc3Ibg"
        />
        <meta name="msvalidate.01" content="8C19FA91ED8C82C3EE0E6BE1010B29F2" />
      </Helmet>
      <NavigationProvider>
        <Header siteTitle={title} />
      </NavigationProvider>
      <PopupProvider>
        <main>{children}</main>
      </PopupProvider>
      <Footer />
    </div>
  )
})`
  margin: 0 auto;
  max-width: 1800px;
  overflow: hidden;

  main {
    padding-top: 70px;
  }
`

Layout.propTypes = {
  children: node.isRequired
}

export default Layout
