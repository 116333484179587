const colors = {
  abbey: "#495057",
  alabaster: "#F9F9F9",
  black: "#000",
  ceruleanBlue: "#2B58CA",
  creamCan: "#F6C959",
  dodgerBlue: "#1980FF",
  facebookBtn: "#3d5c97",
  fern: "#6DC072",
  fruitSalad : "#449d49",
  fuchsiaPink: "#c06dbb",
  gallery: "#ECECEC",
  gamboge: "#DEA10C",
  gray: "#8E8E8E",
  greenLight: "#E3F3E5",
  mercury: "#E5E5E5",
  mossGreen: "#a2d7a6",
  postGreen: "#6DBF70",
  saffron: "#F5C141",
  satinWhite: "#ced4da",
  twitterBtn: "#6fb9f0",
  washedBlack: "#212529",
  white: "#FFF"
}

export default {
  abbey: colors.abbey,
  alabaster: colors.alabaster,
  black: colors.black,
  ceruleanBlue: colors.ceruleanBlue,
  creamCan: colors.creamCan,
  dodgerBlue: colors.dodgerBlue,
  facebookBtn: colors.facebookBtn,
  fern: colors.fern,
  fruitSalad: colors.fruitSalad,
  fuchsiaPink: colors.fuchsiaPink,
  gallery: colors.gallery,
  gamboge: colors.gamboge,
  gray: colors.gray,
  greenLight: colors.greenLight,
  mercury: colors.mercury,
  mossGreen: colors.mossGreen,
  postGreen: colors.postGreen,
  saffron: colors.saffron,
  satinWhite: colors.satinWhite,
  twitterBtn: colors.twitterBtn,
  washedBlack: colors.washedBlack,
  white: colors.white
}
