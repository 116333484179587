import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import LogoMinified from '@images/logo-minified.svg'
import LogoNoIcon from '@images/logo-no-icon.svg'

const Brand = styled(({ className }) => {
  return (
    <Link
      to="/"
      className={`${className} position-relative p-0 brand`}
      aria-label="homepage"
    >
      <div className="logo">
        <LogoNoIcon className="logo-no-icon" />
        <LogoMinified className="logo-minified" />
      </div>
    </Link>
  )
})`
  height: 26px;
  overflow: hidden;
  transition: all 0.2s ease-out;
  width: 186px;

  .logo {
    opacity: 1;
    position: relative;
    transition: all 0.2s ease-out;

    .logo-no-icon {
      opacity: 1;
      position: absolute;
      transition: visibility 0.2s ease-out, opacity 0.2s ease-out;
      visibility: visible;
    }

    .logo-minified {
      height: 25px;
      left: 22px;
      position: absolute;
    }
  }
`

export default Brand
