import { useStaticQuery, graphql } from 'gatsby'

export const useNavigationTablet = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        tabletMain: menus(where: { id: 13 }) {
          edges {
            node {
              menuItems {
                nodes {
                  label
                  url
                  childItems {
                    nodes {
                      label
                      url
                    }
                  }
                }
              }
            }
          }
        }
        tabletServicesSub: menus(where: { id: 14 }) {
          edges {
            node {
              menuItems {
                nodes {
                  label
                  url
                  childItems {
                    nodes {
                      label
                      url
                      description
                    }
                  }
                }
              }
            }
          }
        }
        tabletWorkSub: menus(where: { id: 15 }) {
          edges {
            node {
              menuItems {
                nodes {
                  label
                  url
                  childItems {
                    nodes {
                      label
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return [
    data.wpgraphql.tabletMain.edges[0].node.menuItems.nodes,
    data.wpgraphql.tabletServicesSub.edges[0].node.menuItems.nodes,
    data.wpgraphql.tabletWorkSub.edges[0].node.menuItems.nodes
  ]
}
