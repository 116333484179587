import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const Item = styled(props => <Link {...props} />)`
  background-image: none;
  color: ${colors.abbey};
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  transition: all 100ms linear;

  &:hover {
    color: ${colors.fern};
    text-decoration: none;
  }

  &.active {
    color: ${colors.black};
    text-decoration: none;
  }
`

export default Item