import React, { useContext } from "react"
import { css, keyframes } from "@emotion/core"
import styled from "@emotion/styled"
import { pulse } from "react-animations"

import NavigationContext from "@contexts/navigationContext"

const pulseAnimation = keyframes`${pulse}`

const Wrapper = styled(({ children, className }) => {
  const { active } = useContext(NavigationContext)

  return (
    <div
      className={`${className} ${
        active ? "active" : ""
      } bg-white position-fixed navigation-content-mobile`}
      css={css`
        animation: 0.5s ${pulseAnimation};
      `}
    >
      {children}
    </div>
  )
})`
  bottom: 0;
  display: none;
  left: 0;
  overflow-y: auto;
  padding: 15px;
  right: 0;
  z-index: 1010;

  &.active {
    display: block;
  }

  @media (min-width: 768px) {
    display: none !important;
  }
`

export default Wrapper
