import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// import Hr from "@components/Hr"

import Wrapper from './components/Wrapper'
import Link from './components/Link'
import List from './components/List'

import { useNavigationTablet } from '@hooks/use-navigation-tablet'

const NavigationTablet = () => {
  const tabletMain = useNavigationTablet()[0]
  const tabletServicesSub = useNavigationTablet()[1]
  const tabletWorkSub = useNavigationTablet()[2]

  const currentUrl = typeof window !== 'undefined' && window.location.href

  const target = currentUrl.toString().split('services#')[1]

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col xs="4">
            {tabletMain.map((mainLevel, index) => (
              <div key={index}>
                <Link
                  to={mainLevel.url}
                  activeClassName="active"
                  className="font-weight-bold"
                >
                  {mainLevel.label}
                </Link>
              </div>
            ))}
          </Col>
          <Col xs="4">
            {tabletServicesSub.map((mainLevel, index) => (
              <div key={index}>
                <Link
                  to={mainLevel.url}
                  activeClassName="active"
                  className="font-weight-bold"
                >
                  {mainLevel.label}
                </Link>
                {mainLevel.childItems !== undefined &&
                mainLevel.childItems.nodes.length > 0 ? (
                  <List data={mainLevel.childItems.nodes} target={target} />
                ) : null}
              </div>
            ))}
          </Col>
          <Col xs="4">
            {tabletWorkSub.map((mainLevel, index) => (
              <div key={index}>
                <Link
                  to={mainLevel.url}
                  activeClassName="active"
                  className="font-weight-bold"
                >
                  {mainLevel.label}
                </Link>
                {mainLevel.childItems !== undefined &&
                mainLevel.childItems.nodes.length > 0 ? (
                  <List data={mainLevel.childItems.nodes} target={target} />
                ) : null}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default NavigationTablet
