import React from 'react'
import styled from '@emotion/styled'
import FontAwesomeIcon from '@components/FontAwesomeIcon'

import colors from '@styles/js/colors'

// hooks
import { useThemeSettings } from '@hooks/use-theme-settings'

const Social = styled(({ className }) => {
  const { socialMedias } = useThemeSettings()

  return (
    <div className={`d-none d-xl-block ${className}`}>
      {socialMedias.map((item, index) => (
        <a
          className="network"
          key={index}
          target="_blank"
          aria-label={item.title}
          href={item.link}
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={item.icon} />
        </a>
      ))}
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  float: left;
  z-index: 2;

  .network {
    color: ${colors.gray};
    display: inline-block;
    margin-right: 14px;
    transition: all 250ms linear;

    &:hover {
      color: ${colors.black};
      transform: scale(1.125);
    }
  }
`

export default Social
