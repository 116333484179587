import React, { useContext } from "react"
import styled from "@emotion/styled"
import { HamburgerElastic } from "react-animated-burgers"

import colors from "@styles/js/colors"

import NavigationContext from "@contexts/navigationContext"

const MainIcon = styled(({ className }) => {
  const { active, toggleMenu } = useContext(NavigationContext)

  return (
    <HamburgerElastic
      onClick={() => toggleMenu(!active)}
      isActive={active}
      barColor={active ? colors.saffron : colors.fern}
      className={`${className} d-block d-lg-none p-0 position-relative`}
    />
  )
})`
  top: 4px;

  &:focus {
    outline: 0;
  }
`

export default MainIcon
