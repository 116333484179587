import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'gatsby'

// components
import ProjectIdea from './components/ProjectIdea'
import Chat from './components/Chat'
import CookiePopup from './components/CookiePopup'

// utils
import colors from '@styles/js/colors'

import { useNavigationSimplified } from '@hooks/use-navigation-simplified'
import { useFoooterNavigation } from '@hooks/use-footer-navigation'
import { useThemeSettings } from '@hooks/use-theme-settings'

import Logo from '@images/logo.svg'

const Footer = styled(({ className }) => {
  const navigationSimplified = useNavigationSimplified()
  const footerNavigation = useFoooterNavigation()
  const {
    socialMedias,
    footerlogo,
    projectideatext,
    chatsettings,
    copyright,
    cookie
  } = useThemeSettings()

  return (
    <>
      <div className={`${className} mb-5 mb-md-0`}>
        <Container>
          <Row>
            <Col md="3">
              <Logo className="footer-logo" />
              {/*
              <div className="d-block">
                <ProjectIdea
                  text={projectideatext}
                  logo={footerlogo.imageFile.publicURL}
                />
                <Chat href={chatsettings.link} text={chatsettings.text} />
              </div>
            */}
            </Col>
            <Col md="9" className="text-left text-md-right">
              {navigationSimplified.map((item, index) => (
                <Link
                  activeClassName="active"
                  className="main-navigation-item text-decoration-none"
                  to={item.node.url}
                  key={index}
                >
                  {item.node.label}
                </Link>
              ))}
            </Col>
          </Row>
        </Container>
        <div className="bottomBar">
          <Container>
            <Row>
              <Col md="8" lg="8">
                <div className="socials-wrapper">
                  {socialMedias.map((item, index) => (
                    <a
                      target="_blank"
                      href={item.link}
                      rel="noopener noreferrer"
                      key={index}
                      className="d-block text-gray social"
                    >
                      {item.title}
                    </a>
                  ))}
                </div>
              </Col>
              <Col md="4" lg="4" className="text-right d-none d-md-block pl-0">
                <div className="footerBottomLink">
                  {footerNavigation.map((item, index) => (
                    <Link
                      className="d-inline-block text-decoration-none"
                      to={item.node.url}
                      key={index}
                    >
                      {item.node.label}
                    </Link>
                  ))}
                </div>
              </Col>
              <Col md="12" className="d-none d-md-block">
                <div className="footerBottom">
                  <p>{copyright}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div>
        <CookiePopup data={cookie} />
      </div>
    </>
  )
})`
  padding-top: 40px;

  @media (min-width: 768px) {
    padding-bottom: 40px;
  }

  .footer-logo {
    margin-bottom: 32px;
    width: 180px;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  .main-navigation-item {
    border-bottom: 1px solid #c5c5c5;
    color: #000000;
    display: block;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 8px;
    margin-bottom: 16px;

    &:last-child {
      border: none;
      padding-right: 0;
      margin-bottom: 31px;
    }

    @media (min-width: 768px) {
      border-bottom: 0;
      display: inline-block;
      padding: 0 8px;
      margin-bottom: 0;
    }

    @media (min-width: 1440px) {
      padding: 0 32px;
    }

    &:hover {
      color: ${colors.black};
    }

    &.active {
      color: ${colors.black};
    }
  }

  .social {
    border-bottom: 2px solid ${colors.alabaster};
    padding-bottom: 26px;

    @media (min-width: 768px) {
      border-bottom: 0;
    }

    &:hover {
      color: ${colors.black};
      text-decoration: none;
    }
  }

  .bottomBar {
    border-top: 1px solid #c5c5c5;
    padding-top: 42px;
    margin-top: 18px;

    .socials-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      width: 80%;

      @media (min-width: 768px) {
        justify-content: start;
        width: 100%;
      }

      a {
        border: none;
        padding: 8px 12px;

        @media (min-width: 768px) {
          &:first-of-type {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }

    .footerBottom {
      margin-bottom: 0;
      margin-top: 16px;
      text-align: left;

      @media (min-width: 1440px) {
        margin-top: 28px;
        text-align: left;
      }

      p {
        color: #767676;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'kern' off;
        margin: 0;

        @media (min-width: 768px) {
          line-height: 24px;
        }
      }
    }

    .footerBottomLink {
      text-align: center;

      @media (min-width: 768px) {
        text-align: right;
      }

      a {
        border-bottom: none;
        color: #8e8e8e;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.8px;
        padding-top: 8px;
        padding-bottom: 8px;

        &:after {
          content: '|';
          padding: 0 12px;
        }

        &:last-child {
          border: none;

          &:after {
            display: none;
          }
        }

        @media (max-width: 568px) {
          border: none;
          padding: 0;

          &:after {
            content: '•';
            padding: 0 17px !important;
          }
        }

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: 0.01em;
        }
      }
    }
  }
`

export default Footer
