import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

// components
import TopNav from '@components/TopNav'
import ScrollDisabler from '@components/ScrollDisabler'
import NavigationDesktop from './components/NavigationDesktop'
import NavigationMobile from './components/NavigationMobile'
import NavigationTablet from './components/NavigationTablet'

let tmrDebounceScroll = null
const Header = styled(({ className }) => {
  const [didScroll, setDidScroll] = useState(false)
  const [scrollEnabler, setScrollEnabler] = useState(false)

  const handleScroll = isDefining => () => {
    clearTimeout(tmrDebounceScroll)
    if (!isDefining) return false
    tmrDebounceScroll = setTimeout(() => {
      if (window.scrollY >= 100) {
        setDidScroll(true)
      } else {
        setDidScroll(false)
      }
    }, 50)
  }

  const handleResize = isDefining => () => {
    if (!isDefining) return false
    if (window.matchMedia('(max-width: 991px)').matches) {
      setScrollEnabler(false)
    } else {
      setScrollEnabler(true)
    }
  }
//TODO: Check For Dev Tool Error It is happening when page is loading if you resize the browser
  useEffect(() => {
    let isCancelled = false;
    const runAsync = async () => {
      try {
        if (!isCancelled) {
          window.addEventListener('scroll', handleScroll(true))
          window.addEventListener('resize', handleResize(true))
        }
      } catch (e) {
        if (!isCancelled) {
          throw e;
        }
      }
    };

    runAsync();

    return () => {
      isCancelled = true;
      window.removeEventListener('scroll', handleScroll(false))
      window.removeEventListener('resize', handleResize(false))
    };
  }, [scrollEnabler, didScroll])

  /*useEffect(() => {
    window.addEventListener('scroll', handleScroll(true))
    window.addEventListener('resize', handleResize(true))
    return () => {
      window.removeEventListener('scroll', handleScroll(false))
      window.removeEventListener('resize', handleResize(false))
    }
  }, [scrollEnabler, didScroll])*/

  return (
    <header className={`${className} ${didScroll ? 'scrolled' : ''}`}>
      <ScrollDisabler tag="html" enabled={scrollEnabler} />
      <TopNav>
        <NavigationDesktop />
      </TopNav>
      <NavigationTablet />
      <NavigationMobile />
    </header>
  )
})`
  transition: all 0.2s ease-out;

  .navigation-content-top {
    height: 70px;
    transition: height 0.2s ease-out;
  }

  .navigation-content-mobile,
  .navigation-content-tablet {
    top: 70px;
  }

  &.scrolled {
    .navigation-content-top {
      height: 50px !important;

      .brand {
        .logo-no-icon {
          opacity: 0;
          visibility: hidden;
        }
      }

      .navigation-items {
        margin-left: -160px;
        margin-right: -20px;

        .nav-link {
          @media (min-width: 992px) {
            font-size: 14px;
            padding: 1rem 1.55rem;
          }

          &:after {
            right: 0.6rem;
          }
        }
      }
    }

    .navigation-content-mobile,
    .navigation-content-tablet {
      top: 50px;
    }
  }
`

export default Header
