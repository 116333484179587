import React from "react"
import styled from '@emotion/styled'

import Link from "./components/Link"

import { useNavigationSimplified } from "@hooks/use-navigation-simplified"

const NavigationDesktop = styled(({ className }) => {
  const navigationSimplified = useNavigationSimplified()

  return (
    <div className={`${className} d-none d-lg-block navigation-items`}>
      <ul className="d-flex flex-wrap list-unstyled m-0 pl-0">
        {navigationSimplified.map((item, index) => {
          if (item.node.url !== "/") {
            return (
              <li className="d-flex m-0" key={index}>
                <Link
                  to={item.node.url}
                  className="nav-link"
                  activeClassName="active"
                >
                  {item.node.label}
                </Link>
              </li>
            )
          } else {
            return true
          }
        })}
      </ul>
    </div>
  )
})`
  transition: margin 0.2s ease-out;

`

export default NavigationDesktop
