import { useStaticQuery, graphql } from 'gatsby'

export const useNavigationSimplified = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        menus(where: { id: 8 }) {
          edges {
            node {
              menuItems {
                edges {
                  node {
                    url
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.wpgraphql.menus.edges[0].node.menuItems.edges
}
