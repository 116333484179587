import React from 'react'
import { string } from 'prop-types'
import styled from '@emotion/styled'

// utils
import colors from '@styles/js/colors'

import FooterArrow from '@images/footer-arrow.svg'

const Chat = styled(({ href, text, className }) => (
  <a href={href} className={`${className} d-flex align-items-center text-decoration-none`}>
    <span>{text}</span>
    <FooterArrow className="arrow"/>
  </a>
))`
  color: ${colors.black};
  padding-left: 58px;

  @media (min-width: 768px) {
    padding-left: 78px;
  }

  &:hover {
    color: ${colors.black};
  }

  span {
    font-size: 20px;
    line-height: 32px;

    @media (min-width: 768px) {
      font-size: 28px;
    }
  }

  .arrow{
    margin-left: 30px;
  }

  .icon {
    height: 40px;
    margin-left: 30px;
    width: 40px;

    @media (min-width: 768px) {
      height: 28px;
      width: 28px;
    }
  }
`

Chat.propTypes = {
  href: string.isRequired,
  text: string.isRequired,
  chatIcon: string.isRequired
}

Chat.defaultProps = {
  href: null,
  text: '',
  chatIcon: ''
}

export default Chat
