import React from 'react'
import { string } from 'prop-types'
import styled from '@emotion/styled'

// utils
import colors from '@styles/js/colors'

const ProjectIdea = styled(({ text, logo, className }) => (
  <div className={`${className} d-flex align-items-center`}>
    <img className="logo" src={`${logo}`} alt="Footer Logo" />
    <span className="position-relative">{text}</span>
  </div>
))`
  .logo {
    height: 40px;
    width: 40px;

    @media (min-width: 768px) {
      height: 50px;
      margin-right: 26px;
      width: 50px;
    }
  }

  span {
    color: ${colors.black};
    font-size: 20px;
    line-height: 32px;
    padding-left: 17px;

    @media (min-width: 768px) {
      font-size: 28px;
      padding-left: 0px;
    }

    /*@media (min-width: 992px) {
      &:after {
        background-color: ${colors.saffron};
        content: '';
        display: block;
        height: 2px;
        left: 115%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
      }
    }*/
  }
`

ProjectIdea.propTypes = {
  text: string.isRequired
}

ProjectIdea.defaultProps = {
  text: ''
}

export default ProjectIdea
