import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const Link = styled(props => <GatsbyLink {...props} />)`
  background-image: none;
  color: ${colors.abbey};
  display: inline-block;
  font-size: 24px;
  margin-bottom: 1rem;
  position: relative;
  transition: all 200ms linear;

  &:hover {
    color: ${colors.black};
    text-decoration: none;
  }

  &.active {
    color: ${colors.black};

    &:after {
      background: ${colors.fern};
      border-radius: 50%;
      content: "";
      height: 12px;
      position: absolute;
      right: -22px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 200ms linear;
      width: 12px;
    }
  }
`

export default Link
