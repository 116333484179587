import React from 'react'
import { string } from 'prop-types'
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon as FontIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'

fontawesome.library.add(fab)

const FontAwesomeIcon = ({ icon }) => (
  <FontIcon size="lg" icon={['fab', icon]} />
)

FontAwesomeIcon.propTypes = {
  icon: string.isRequired
}

FontAwesomeIcon.defaultProps = {
  icon: null
}

export default FontAwesomeIcon
