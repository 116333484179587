import React from 'react'
import { string, arrayOf, object, PropTypes } from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
const SEO = ({ description, keyword, lang, meta, title, image }) => {
  const pathname = typeof window !== 'undefined' ? window.location : ''
  const { site } = useStaticQuery(query)
  const { siteUrl, defaultImage, twitterUsername } = site.siteMetadata
  const seo = {
    image: `${siteUrl}/${image || defaultImage}`,
    url: `${siteUrl}${pathname}`
  }
  /*
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            defaultImage: image
            twitterUsername
          }
        }
      }
    `
  )
  */
  const metaDescription = description || site.siteMetadata.description
  const mainMeta = [
    {
      name: `description`,
      content: metaDescription
    },
    {
      property: `og:title`,
      content: title
    },
    {
      property: `og:description`,
      content: metaDescription
    },
    {
      property: `og:type`,
      content: `website`
    },
    {
      name: `twitter:card`,
      content: `summary`
    },
    {
      name: `twitter:creator`,
      content: twitterUsername
    },
    {
      name: `twitter:title`,
      content: title
    },
    {
      name: `twitter:description`,
      content: metaDescription
    }
  ]
  if (seo.image) {
    mainMeta.push({
      name: `image`,
      content: seo.image
    })
    mainMeta.push({
      property: `og:image`,
      content: seo.image
    })
    mainMeta.push({
      name: `twitter:image`,
      content: seo.image
    })
  }
  if (keyword) {
    mainMeta.push({
      name: `keywords`,
      content: keyword
    })
  }
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s`} // | ${site.siteMetadata.title}
      meta={mainMeta.concat(meta)}
    />
  )
}
SEO.defaultProps = {
  description: ``,
  image: null,
  keyword: null,
  lang: `en`,
  meta: []
}
SEO.propTypes = {
  title: string.isRequired,
  description: string,
  image: PropTypes.string,
  keyword: string,
  lang: string,
  meta: arrayOf(object)
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
export default SEO
