import React, { useState } from 'react'
import { node } from 'prop-types'

import PopupContext from '@contexts/popupContext'

const PopupProvider = ({ children }) => {
  const toggleDownloadPopup = () => {
    setOpen(prevState => {
      return {
        ...prevState,
        activeDownloadPopup: !prevState.activeDownloadPopup
      }
    })
  }

  const loadingState = {
    activeDownloadPopup: false,
    toggleDownloadPopup
  }

  const [open, setOpen] = useState(loadingState)

  return <PopupContext.Provider value={open}>{children}</PopupContext.Provider>
}

PopupProvider.propTypes = {
  children: node
}

PopupProvider.defaultProps = {
  children: null
}

export default PopupProvider
