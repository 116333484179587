import React, { useState } from "react"
import { node } from "prop-types"

import NavigationContext from "@contexts/navigationContext"

const NavigationProvider = ({ children }) => {

  const toggleMenu = () => {
    setOpen(prevState => {
      return {
        ...prevState,
        active: !prevState.active
      }
    })
  }

  const loadingState = {
    active: false,
    toggleMenu
  }

  const [open, setOpen] = useState(loadingState)

  return (
    <NavigationContext.Provider value={open}>
      {children}
    </NavigationContext.Provider>
  )
}

NavigationProvider.propTypes = {
  children: node
}

NavigationProvider.defaultProps = {
  children: null
}

export default NavigationProvider
