import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import Social from '@components/Social'
import Brand from './components/Brand'
import MainIcon from './components/MainIcon'

import MailIcon from '@images/mail-icon.svg'

import { useThemeSettings } from '@hooks/use-theme-settings'

const TopNav = styled(({ className, children }) => {
  const { email } = useThemeSettings()

  return (
    <div className={`${className} position-relative`}>
      <div className={`position-fixed bg-white top-nav-wrapper`}>
        <Container>
          <Row>
            <Col>
              <div className="d-flex align-items-center justify-content-between bg-white navigation-content-top">
                <Brand />
                {children}
                <div className="d-flex align-items-center text-black">
                  <Social />
                  <div className="d-flex d-xl-none align-items-center">
                    <a href={`mailto:${email}`} className="mail-icon">
                      <MailIcon />
                    </a>
                  </div>
                  <MainIcon />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})`
  max-width: 1800px !important;
  width: 100%;

  .top-nav-wrapper {
    left: 0;
    right: 0;
    width: inherit;
    z-index: 9999;

    .mail-icon {
      height: 31px;
      position: relative;
      margin-right: 16px;
      top: 4px;
    }
  }
`

export default TopNav
