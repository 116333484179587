import React, { useContext } from "react"
import { css, keyframes } from "@emotion/core"
import styled from "@emotion/styled"
import { pulse } from "react-animations"

import NavigationContext from "@contexts/navigationContext"

const pulseAnimation = keyframes`${pulse}`

const Wrapper = styled(({ children, className }) => {
  const { active } = useContext(NavigationContext)

  return (
    <div
      className={`${className} ${active ? "active" : ""} bg-white position-fixed navigation-content-tablet`}
      css={css`
        animation: 0.5s ${pulseAnimation};
      `}
    >
      {children}
    </div>
  )
})`
  display: none;
  left: 0;
  padding: 20px 0;
  right: 0;
  transition: all 100ms linear;
  z-index: 1030;

  @media (max-width: 767px) {
    display: none !important;
  }

  @media (min-width: 992px) {
    display: none !important;
  }

  &.active {
    display: block;
  }
`

export default Wrapper
