import { Link as GatsbyLink } from 'gatsby'
import styled from '@emotion/styled'

import colors from '@styles/js/colors'

const Link = styled(GatsbyLink)`
  color: ${colors.abbey};
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 18px;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  transition: all 200ms linear;

  @media (min-width: 1200px) {
    padding: 0 24px;
  }

  &:after {
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 9px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transition: all 200ms linear;
    width: 9px;

    @media (min-width: 1200px) {
      right: 10px;
    }
  }

  &:hover {
    color: ${colors.black};
    text-decoration: none;

    &:after {
      background: ${colors.fern};
      transform: translateY(-50%) scale(1);
    }
  }

  &.active {
    color: ${colors.black};

    &:after {
      background: ${colors.saffron};
      transform: translateY(-50%) scale(1);
    }
  }
`

export default Link
