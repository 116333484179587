import React, { useContext } from 'react'
import { array } from 'prop-types'

import Item from './components/Item'

import NavigationContext from '@contexts/navigationContext'

const List = ({ data }) => {
  const { active, toggleMenu } = useContext(NavigationContext)

  const currentURL = typeof window !== 'undefined' && window.location.href

  const target = currentURL.toString().split('#')[1]

  return (
    <>
      {data !== null &&
        data.map((item, index) => (
          <Item
            to={item.url}
            key={index}
            onClick={() => toggleMenu(!active)}
            className={`${item.description !== undefined && target === item.description ? 'active' : ''}`}
          >
            {item.label}
          </Item>
        ))}
    </>
  )
}

List.propTypes = {
  data: array
}

List.defaultProps = {
  data: []
}

export default List
