import React, { useContext } from 'react'
import { string, bool } from 'prop-types'
import { Helmet } from 'react-helmet'

import NavigationContext from '@contexts/navigationContext'

const ScrollDisabler = ({ tag, enabled }) => {
  const { active } = useContext(NavigationContext)

  return active ? (
    <Helmet
      style={[
        {
          cssText: `
            ${tag} {
              margin: 0;
              height: 100%;
              overflow: ${enabled ? 'auto' : 'hidden'};
            }
        `
        }
      ]}
    />
  ) : (
    ''
  )
}

ScrollDisabler.propTypes = {
  tag: string.isRequired,
  enabled: bool.isRequired
}

ScrollDisabler.defaultProps = {
  tag: '',
  enabled: false
}

export default ScrollDisabler
