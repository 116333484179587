import { useStaticQuery, graphql } from 'gatsby'

export const useThemeSettings = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        themeSettings {
          settingFields {
            socialMedias {
              title
              link
              icon
            }
            email
            footerlogo {
              sourceUrl
              mediaItemId
              modified
              imageFile {
                publicURL
              }
            }
            projectideatext
            chatsettings {
              text
              link
              icon {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  publicURL
                }
              }
            }
            copyright
            cookie {
              content
              secondButtonTitle
              secondButtonLink
              acceptButton
              title
            }
          }
        }
      }
    }
  `)

  return data.wpgraphql.themeSettings.settingFields
}
