import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const Item = styled.li`
  border-bottom: 1px solid ${colors.mercury};
  margin-bottom: 0;
`

export default Item
