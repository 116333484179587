import React from "react"

import Wrapper from "./components/Wrapper"
import List from "./components/List"
import Item from "./components/Item"
import Link from "./components/Link"

import { useNavigationSimplified } from "@hooks/use-navigation-simplified"

const NavigationMobile = () => {
  const navigationSimplified = useNavigationSimplified()

  return (
    <Wrapper>
      <List>
        {navigationSimplified.map((item, index) => (
          <Item key={index}>
            <Link to={item.node.url} activeClassName="active">
              {item.node.label}
            </Link>
          </Item>
        ))}
      </List>
    </Wrapper>
  )
}

export default NavigationMobile
